<template>
  <div class="profile-info">
    <div class="text-right">
      <h6>
        <button
          class="text-primary bg-white"
          style="border: none; outline: none; font-weight: 500"
          @click="expandAll()"
        >
          {{ isExpandAll ? "- Collapse All" : "+ Expand All" }}
        </button>
      </h6>
    </div>
    <div
      class="specialization-section pb-2 border-bottom"
      v-if="isNurse || isAHP || isNonClinicAdmin"
    >
      <SpecializationInfo
        :profile="profile"
        :specialInterests="getCandidateSpecialInterest"
        :isMobile="isMobile"
      />
    </div>
    <div class="qualification-section">
      <QualificationInfoNurse
        v-if="isNurse || isAHP"
        :profile="profile"
        :qualifications="qualifications"
        :isMobile="isMobile"
        :specialInterests="getCandidateSpecialInterest"
      />
      <QualificationInfo
        v-else
        :profile="profile"
        :qualifications="qualifications"
        :isMobile="isMobile"
        :specialInterests="getCandidateSpecialInterest"
      />
    </div>
    <div class="language-score-section pt-4 border-top">
      <LanguageScores 
      :profile="profile"
      />

    </div>
    <div
      v-if="isNonClinicAdmin"
      class="experience-info-section pt-4 border-top"
    >
      <ExperienceInfo
        :profile="profile"
        :ExperienceInfo="ExperienceInfos"
        :isMobile="isMobile"
      />
    </div>
    <div class="additional-training pt-4 border-top">
      <AdditionalTraining :profile="profile" :isMobile="isMobile" />
    </div>
    <div
      class="pqr-details-sectio pt-4 border-top"
      v-if="!isCandidate && !isNonClinicAdmin"
    >
      <PQRInfo
        :profile="profile"
        :pqrLevels="pqrLevels"
        :candidatePQRLevel="getCandidatePQRLevel"
        @calculatePQRLevel="checkPQRLevel"
      />
    </div>
    <div
      v-if="!isAHP"
      class="specialInterest-info-section pt-4 border-top"
    >
      <SpecialInterestInfo
        :profile="profile"
        :specialInterests="specialInterests"
        :isMobile="isMobile"
        :isNonClinicAdmin="isNonClinicAdmin"
      />
    </div>
    <div class="document-info-section pt-4 border-top">
      <DocumentInfo
        :profile="profile"
        :isMobile="isMobile"
        :documents="documents"
        :resumeDocument="resumeDocument"
      />
    </div>
    <div
      v-if="!isNonClinicAdmin"
      class="license-info-section mt-2 pt-4 border-top"
    >
      <LicenseInfo
        :licenses="licenses"
        :profile="profile"
        :isMobile="isMobile"
      />
    </div>
    <div class="language-info-section pt-4 border-top">
      <LanguageInfo
        :LanguageSpokenInfo="LanguageSpokenInfo"
        :isMobile="isMobile"
      />
    </div>
    <!-- <div class="language-info-section mt-2 pt-4 border-top">
      <NonMedicalInfo
        :isNonClinicAdmin="isNonClinicAdmin"
        :profile="profile"
        :NonMedicalInfo="NonMedicalInfos"
        :isMobile="isMobile"
      />
    </div> -->
    <div class="document-info-section mt-2 pt-4 border-top">
      <CandidateDocumentInfo
        :isMobile="isMobile"
        :profile="profile"
        :offerDocument="offerDocument"
        :onBoardDocument="onBoardDocument"
      />
    </div>
    <div class="privacyAndTerms-info-section mt-2 pt-4 border-top">
      <PrivacyAndTerms :profile="profile" />
    </div>
  </div>
</template>
<script>
import m from "moment";
import { Role, getScope } from "@/helpers/helper";
import { mapActions, mapGetters } from "vuex";
import QualificationInfo from "@/containers/CandidateDetailsTalentFind/ProfileSection/Qualification/QualificationInfo";
import DocumentInfo from "@/containers/CandidateDetailsTalentFind/ProfileSection/DocumentInfo";
import PQRInfo from "@/containers/CandidateDetailsTalentFind/ProfileSection/PQRInfo/PQRInfo";
import NonMedicalInfo from "@/containers/CandidateDetailsTalentFind/ProfileSection/NonMedicalInfo/NonMedicalInfo";
import LanguageInfo from "@/containers/CandidateDetailsTalentFind/ProfileSection/LanguageInfo";
import SpecialInterestInfo from "@/containers/CandidateDetailsTalentFind/ProfileSection/SpecialInterestInfo";
import LicenseInfo from "@/containers/CandidateDetailsTalentFind/ProfileSection/License/LicenseInfo";
import PrivacyAndTerms from "@/containers/CandidateDetailsTalentFind/ProfileSection/T&CandGDPRStatementInfo";
import Modal from "@/components/reusable/Modal";
import SpecializationInfo from "@/containers/CandidateDetailsTalentFind/ProfileSection/SpecializationInfoNurse";
import QualificationInfoNurse from "@/containers/CandidateDetailsTalentFind/ProfileSection/QualificationNurse/QualificationInfoNurse";
import ExperienceInfo from "@/containers/CandidateDetailsTalentFind/ProfileSection/ExperienceInfo/ExperienceInfo";
import CandidateDocumentInfo from "@/containers/CandidateDetailsTalentFind/ProfileSection/CandidateDocumentInfo";
import AdditionalTraining from "@/containers/CandidateDetailsTalentFind/ProfileSection/AdditionalTrainingInfo";
import LanguageScores from "@/containers/CandidateDetailsTalentFind/ProfileSection/LanguageScores";
export default {
  props: {
    profile: {
      type: Object,
      default: () => {},
    },
    qualifications: {
      type: Array,
      default: () => [],
    },
    resumeDocument: {
      type: Array,
      default: () => [],
    },
    documents: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    QualificationInfo,
    DocumentInfo,
    PQRInfo,
    LanguageInfo,
    NonMedicalInfo,
    SpecialInterestInfo,
    LicenseInfo,
    PrivacyAndTerms,
    Modal,
    SpecializationInfo,
    QualificationInfoNurse,
    ExperienceInfo,
    CandidateDocumentInfo,
    AdditionalTraining,
    LanguageScores
  },
  data() {
    return {
      offer_ids: [],
      showstatus: false,
      isMobile: false,
      qualification_step: 0,
      disabledBtnDocID: [],
      isExpand: {
        qualification: false,
        NonMedicalQual: false,
        experience: false,
        pqr: false,
        specialInterest: false,
        document: false,
        license: false,
        language: false,
        privacyAndTerms: false,
        specialization: false,
        Canddocument: false,
        additionalTraining: false,
        languageScores: false,
      },
      isExpandAll: false,
      currentUserRole: getScope(),
      Role,
    };
  },
  mounted() {
    let isMobile = window.matchMedia(
      "only screen and (max-width: 420px)"
    ).matches;
    this.isMobile = isMobile;
    this.fetchOffersByCandidate(this.profile.candidate_uid);
  },
  computed: {
    ...mapGetters([
      "getCandidatePQRLevels",
      "getCandidatePQRLevel",
      "getCandidateLanguage",
      "getCandidateNonMedicalQuals",
      "getCandidateExperiences",
      "getCandidateSpecialInterest",
      "getLicense",
      "getSkills",
      "isCandidateSupplierFromAccessToken",
      "fetchOffer",
      "SET_PARENT_DETAILS",
      "offerDocumentActions",
      "onboardDocumentActions",
    ]),
    offerDocument() {
      return (
        this.offerDocumentActions &&
        this.offerDocumentActions.reduce((acc, a) => {
          if (a.previous_action_id == null && a.actioned_by === "recruiter") {
            const { job_id, job, action_type, candidate_uid } = a;
            let temp = acc.find((x) => x.job_id === a.job_id);
            let returnAction =
              this.offerDocumentActions.filter(
                ({ previous_action_id, job_id: return_job_id }) =>
                  job_id &&
                  return_job_id &&
                  previous_action_id === a.document_action_id
              ) || [];
            if (!temp) {
              acc.push({
                job_id,
                job,
                action_type,
                candidate_uid,
                actions: [{ ...a, returnAction }],
              });
            } else {
              temp.actions.push({ ...a, returnAction });
            }
          }
          return acc;
        }, [])
      );
    },
    onBoardDocument() {
      return (
        this.onboardDocumentActions &&
        this.onboardDocumentActions.reduce((acc, a) => {
          if (a.previous_action_id == null && a.actioned_by === "recruiter") {
            const { job_id, job } = a;
            let temp = acc.find((x) => x.job_id === a.job_id);
            let returnAction =
              this.onboardDocumentActions.filter(
                ({ previous_action_id, job_id: return_job_id }) =>
                  job_id &&
                  return_job_id &&
                  previous_action_id === a.document_action_id
              ) || [];
            if (!temp) {
              acc.push({
                job_id,
                job,
                actions: [{ ...a, returnAction }],
              });
            } else {
              temp.actions.push({ ...a, returnAction });
            }
          }
          return acc;
        }, [])
      );
    },
    // getCandidateSpecialInterest() {
    //   return (
    //     (this.profile?.candidate_specialities &&
    //       this.profile?.candidate_specialities) ||
    //     []
    //   );
    // },
    pqrLevels() {
      return (
        this.getCandidatePQRLevels
          .filter((val) => val?.potential === null)
          .map(
            ({
              pqr_detail,
              description,
              comments,
              created_on,
              candidate_qualification,
              candidate_license,
              pqr_ahp_detail,
              working_level_id,
              working_candidate_level,
            }) => {
              return {
                country_name:
                  pqr_detail?.pqr_header?.country_name ||
                  pqr_ahp_detail?.pqr_header?.country_name ||
                  null,
                issue_authority_name:
                  pqr_detail?.pqr_header?.issue_authority_name ||
                  pqr_ahp_detail?.pqr_header?.issue_authority_name ||
                  null,
                candidate_level: pqr_detail?.level?.candidate_level || null,
                pqr_detail_description:
                  pqr_detail?.level?.country_id == 182
                    ? !working_candidate_level && !pqr_detail?.comments
                      ? null
                      : `${
                          working_candidate_level
                            ? `Qualifies as a ${working_candidate_level}`
                            : ``
                        }` +
                        `${
                          pqr_detail?.comments && working_candidate_level
                            ? `- Eligible for a ${(pqr_detail?.comments).replace(
                                "required",
                                ""
                              )}`
                            : pqr_detail?.comments
                            ? `Eligible for a ${(pqr_detail?.comments).replace(
                                "required",
                                ""
                              )}`
                            : ``
                        }`
                    : pqr_detail?.description ||
                      pqr_ahp_detail?.description ||
                      null,
                description,
                comments,
                //working_level_id :  "",
                created_on,
                candidate_qualification,
                candidate_license,
                pqr_ahp_detail,
                pqr_detail,
                potential: this.checkPotential(
                  pqr_detail?.pqr_header?.issue_authority_id ||
                    pqr_ahp_detail?.pqr_header?.issue_authority_id
                ),
                raw_candidate_level: pqr_detail?.level?.candidate_level,
                working_candidate_level,
              };
            }
          ) || []
      );
    },
    ExperienceInfos() {
      return (
        (this.getCandidateExperiences?.length &&
          this.getCandidateExperiences.map(
            ({
              experience_id,
              organisation_type_id,
              organisation_nature,
              organisation_additional_info,
              organisation_additional_info_other,
              organisation_location_id,
              organisation_bedcount,
              direct_reports_count,
              is_current_org,
              start_month,
              start_year,
              end_year,
              end_month,
              designation,
              organisation_type,
              organisation_location,
              level_id,
              level,
            }) => {
              return {
                experience_id: experience_id !== null ? experience_id : "--",
                organisation_type_id: organisation_type_id
                  ? organisation_type_id
                  : null,
                organisation_type: organisation_type?.organisation_type
                  ? organisation_type?.organisation_type
                  : null,
                organisation_nature: organisation_nature
                  ? organisation_nature
                  : null,
                organisation_nature_label: organisation_nature
                  ? _.startCase(organisation_nature)
                  : null,
                organisation_location: organisation_location?.country_name
                  ? _.startCase(organisation_location?.country_name)
                  : null,
                organisation_additional_info_id: organisation_additional_info[0]
                  ? organisation_additional_info[0]
                  : null,
                organisation_additional_info: organisation_additional_info[0]
                  ? _.startCase(organisation_additional_info[0]) +
                    " Hospital/Facility "
                  : null,
                organisation_additional_info_other:
                  organisation_additional_info_other
                    ? organisation_additional_info_other
                    : null,
                organisation_location_id: organisation_location_id
                  ? organisation_location_id
                  : "--",
                organisation_bedcount: organisation_bedcount
                  ? organisation_bedcount
                  : "--",
                direct_reports_count: direct_reports_count
                  ? direct_reports_count
                  : "--",
                is_current_org: is_current_org !== null ? is_current_org : null,
                is_current_employement:
                  is_current_org !== null
                    ? is_current_org != false
                      ? "Yes"
                      : "No"
                    : "--",
                start_month: start_month ? start_month : null,
                start_year: start_year ? start_year : null,
                end_month: end_month ? end_month : null,
                end_year: end_year ? end_year : null,
                designation: designation !== null ? designation : null,
                level_id: level_id ? level_id : null,
                level_name: level?.candidate_level
                  ? level?.candidate_level
                  : null,
                experience_in_years: this.experienceInYears(
                  start_year,
                  end_year
                ),
                experience_in_months: this.experienceInMonths(
                  start_month,
                  end_month
                ),
              };
            }
          )) ||
        []
      );
    },
    NonMedicalInfos() {
      return (
        this.getCandidateNonMedicalQuals.map(
          ({
            id,
            non_medical_qual,
            non_medical_qual_id,
            name,
            score,
            skill_level,
            month,
            year,
            others_name,
            others_score,
            expired_message,
          }) => {
            return {
              id: id !== null ? id : "--",
              non_medical_qual_id: non_medical_qual
                ? non_medical_qual?.id
                : null,
              name: non_medical_qual?.name ? non_medical_qual?.name : "--",
              qual_type_id: non_medical_qual?.non_medical_qual_type
                ? non_medical_qual?.non_medical_qual_type?.id
                : "--",
              qual_type_name: non_medical_qual?.non_medical_qual_type?.type_name
                ? non_medical_qual?.non_medical_qual_type?.type_name
                : "--",
              score: non_medical_qual ? non_medical_qual?.score : "--",
              skill_level: non_medical_qual
                ? non_medical_qual?.skill_level
                : "--",
              month: month !== null ? month : null,
              year: year !== null ? year : null,
              others_name: others_name !== null ? others_name : "--",
              others_score: others_score !== null ? others_score : "--",
              expired_message: this.expiryCheck(month, year, non_medical_qual),
            };
          }
        ) || []
      );
    },
    LanguageSpokenInfo() {
      return (
        this.getCandidateLanguage.map(
          ({
            language_id,
            candidate_language_id,
            language,
            language_proficiency,
            comments,
          }) => {
            return {
              language_id: language.language_id,
              candidate_language_id: candidate_language_id,
              language: language.language,
              proficiency: language_proficiency?.proficiency,
              comments: comments,
            };
          }
        ) || []
      );
    },
    specialInterests() {
      return (
        this.getCandidateSpecialInterest
          .filter(
            (val) => val.special_interest_id || val.other_special_interest
          )
          .map(
            ({
              candidate_speciality_id,
              special_interest,
              other_special_interest,
            }) => {
              return {
                candidate_speciality_id: candidate_speciality_id,
                special_interest_id:
                  special_interest != null
                    ? special_interest.special_interest_id
                    : null,
                special_interest_name:
                  special_interest !== null
                    ? special_interest.special_interest
                    : "Other",
                other_special_interest: other_special_interest,
              };
            }
          ) || []
      );
    },
    licenses() {
      return (
        this.getLicense.map(
          ({
            candidate_license_id,
            license_number,
            country_name,
            issue_date,
            expiry_date,
            current_license,
            speciality_name,
            level_id,
            level_name,
            specialist_registration,
            eligibility_letter,
            speciality,
            country_id,
            issue_authority_id,
            issue_authority_name,
            specialist_registration_year,
            specialist_registration_month,
            document_ids,
            documents,
            other_issue_authority,
          }) => {
            return {
              candidate_license_id: candidate_license_id,
              license_number: license_number,
              country_name: country_name,
              issue_date: issue_date,
              expiry_date: expiry_date,
              current_license: current_license,
              speciality_name: speciality_name,
              level_name: level_name,
              level_id: level_id,
              specialist_registration: specialist_registration,
              eligibility_letter: eligibility_letter,
              speciality_type: speciality?.speciality_type?.speciality_type,
              country_id,
              speciality_type_id:
                speciality?.speciality_type?.speciality_type_id || null,
              issue_authority_id:
                issue_authority_name !== null && other_issue_authority === null
                  ? issue_authority_id
                  : issue_authority_name === null &&
                    other_issue_authority !== null
                  ? `Others`
                  : "--",
              issue_authority_name:
                issue_authority_name !== null && other_issue_authority === null
                  ? issue_authority_name
                  : issue_authority_name === null &&
                    other_issue_authority !== null
                  ? `Other - not listed`
                  : "--",
              other_issue_authority: other_issue_authority,
              date_acquired:
                specialist_registration_month && specialist_registration_year
                  ? `${specialist_registration_year}-${specialist_registration_month}`
                  : null,
              document_ids: document_ids,
              documents: documents,
            };
          }
        ) || []
      );
    },
    skills() {
      return (
        this.getSkills.map(
          ({
            candidate_skill_id,
            skill_id,
            skill_name,
            skill,
            date_attained,
            procedures_performed,
            description,
          }) => {
            return {
              candidate_skill_id: candidate_skill_id,
              skill_id: skill_id,
              skill_name: skill.skill,
              date_attained: date_attained,
              procedures_performed: procedures_performed,
              description: description,
            };
          }
        ) || []
      );
    },
    isNurse() {
      return this.profile?.candidate_type_id === 2 || false;
    },
    isAHP() {
      return this.profile?.candidate_type_id === 3 || false;
    },
    isNonClinicAdmin() {
      return this.profile?.candidate_type_id
        ? this.profile.candidate_type_id === 6
        : false;
    },
    isCandidate() {
      return [Role.customerCandidate, Role.systemCandidate].includes(
        this.currentUserRole
      );
    },
    gettingjobids() {
      this.jobids = [];
      let tempjobids = [];
      this.fetchOffer.map((res) => {
        {
          tempjobids.push(res.job_id);
          this.offer_ids.push(res.offer_id);
        }
      });

      return tempjobids.length;
    },
  },
  methods: {
    ...mapActions([
      "downloadDocument",
      "checkPQRLevel",
      "deleteDocument",
      "fetchOffersByCandidate",
    ]),
    fileDownload(data, docId) {
      if (docId) this.disabledBtnDocID.push(docId);
      let { link, name: document_name } = data;
      let url = link.replace("/api/v1/", "");
      this.downloadDocument({ url, document_name }).finally(() => {
        if (docId) {
          this.disabledBtnDocID = this.disabledBtnDocID.filter(
            (i) => i != docId
          );
        }
      });
      return false;
    },
    expandAll() {
      this.isExpandAll = !this.isExpandAll;
      for (const [key, value] of Object.entries(this.isExpand)) {
        this.isExpand[key] = this.isExpandAll;
      }
    },
    checkExpandAll() {
      let temp = false;
      for (const [key, value] of Object.entries(this.isExpand)) {
        value ? (temp = true) : null;
      }
      if (temp) this.isExpandAll = false;
    },
    resetExpand() {
      for (const [key, value] of Object.entries(this.isExpand)) {
        this.isExpand[key] = false;
      }
      this.isExpandAll = false;
    },
    tooltipHide() {
      setTimeout(() => {
        let x = document.querySelectorAll(".tooltip");
        for (var i = 0; i < x.length; i++) {
          x[i].style.visibility = "hidden";
        }
      }, 50);
    },
    experienceInYears(start_year, end_year) {
      let present_year = m(new Date()).year();
      return start_year && end_year
        ? m(end_year).diff(m(start_year))
        : m(present_year).diff(start_year);
    },
    experienceInMonths(start_month, end_month) {
      let present_month = m(new Date()).month();
      return start_month && end_month
        ? m(end_month).diff(m(start_month))
        : m(present_month).diff(start_month);
    },
    expiryCheck(month, year, non_medical_qual) {
      let today = new Date();
      let expires_in = non_medical_qual?.expires_in
        ? non_medical_qual?.expires_in
        : null;
      if (month && year && expires_in) {
        year += expires_in;
        let expiration_date = new Date(
          `${month != 12 ? year : year + 1}-${month != 12 ? month + 1 : 1}-01`
        );
        if (expiration_date <= today) {
          let expired_message = non_medical_qual?.expired_message;
          return expired_message;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    checkPotential(issue_authority_id) {
      return (
        this.getCandidatePQRLevels
          .filter(
            (val) =>
              val?.potential &&
              (val?.pqr_detail?.pqr_header?.issue_authority_id ===
                issue_authority_id ||
                val?.pqr_ahp_detail?.pqr_header?.issue_authority_id ===
                  issue_authority_id)
          )
          .map((v) => ({
            pqr_detail_description:
              v?.pqr_detail?.description ||
              v?.pqr_ahp_detail?.description ||
              null,
            comments: v?.comments,
          })) || []
      );
    },
  },
};
</script>
